import * as React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { RiPriceTagLine, RiCalendarLine } from "react-icons/ri/"
import { FcEditImage } from "react-icons/fc/"
import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="ホーム" />
        <p>
          No blog posts found.
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="ホーム" />
      <ol style={{ listStyle: `none`}} className="post-ol">
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug
          const cover = getImage(post.frontmatter.cover)
          const tags = post.frontmatter.tags

          let coverImage = (cover) ? 
          (<GatsbyImage image={cover} alt={title} />)
            : (<div className="cover_icon" style={{backgroundColor: `#d1dce5`}}><FcEditImage size={150} /></div>)

          return (
            <li key={post.fields.slug} className="post-li">
              <Link to={post.fields.slug} itemProp="url" style={{textDecoration: `none`}}>
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                {coverImage}
                <header>
                  <h1>
                    <span itemProp="headline">{title}</span>
                  </h1>
                  <p className="meta">
                  <span><RiCalendarLine size={15} />{post.frontmatter.date}</span>
                  {tags.map(tag =>
                    <span key={tag}>
                      <RiPriceTagLine size={15} /> {tag}
                    </span>
                  )}
                  </p>
                </header>
                <section>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: post.frontmatter.description || post.excerpt,
                    }}
                    itemProp="description"
                  />
                </section>
              </article>
              </Link>
            </li>
          )
        })}
      </ol>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          tags
          cover {
            childImageSharp {
              gatsbyImageData(
                width: 800
                height: 360
                quality: 90
                placeholder: BLURRED
                transformOptions: {cropFocus: CENTER}
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
  }
`
